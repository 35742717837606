<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row property-tab-title">
      <div class="text-left">
        <h3 class="mb-4">
          {{ `${auction.name}` }} - {{ $t("COMMON.PROPERTIES") }}
        </h3>
      </div>
      <div class="text-right">
        <base-dropdown
          title-classes="btn dropdown-button export"
          menu-on-right
          :has-toggle="false"
        >
          <template slot="title">
            <base-button>
              <span class="text">
                {{ $t("COMMON.EXPORT") }}
              </span>
              <span class="icon">
                <i class="far fa-chevron-down"></i>
              </span>
            </base-button>
          </template>
          <base-button
            v-if="$currentUserCan($permissions.PERM_EXPORTS_PROPERTIES)"
            @click="triggerDataExport(false)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-arrow-alt-circle-up"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("PROPERTIES.EXPORT_ALL") }}
            </span>
          </base-button>
          <base-button
            v-if="$currentUserCan($permissions.PERM_EXPORTS_PROPERTIES)"
            @click="triggerDataExport(true)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-arrow-alt-circle-up"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("PROPERTIES.EXPORT_CONFIRMED") }}
            </span>
          </base-button>

          <base-button @click="exportPublicNotices()">
            <span class="btn-inner--icon">
              <i class="fa fa-arrow-alt-circle-up"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("PROPERTIES.PUBLIC_NOTICES")
            }}</span>
          </base-button>

          <base-button
            :disabled="selectedProperties.length !== 0"
            @click="openDetailsModal()"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-print"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("PROPERTIES.DIRECT_MAIL")
            }}</span>
          </base-button>
          <base-button
            :disabled="selectedProperties.length === 0"
            @click="openDetailsModal()"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-print"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("PROPERTIES.SELECTED_DIRECT_MAIL")
            }}</span>
          </base-button>
        </base-dropdown>
      </div>
    </div>
    <property-list-table
      :isSelectable="true"
      @onSelectedProperties="getSelectedProperties"
      :filterAuctionId="auction.id"
      ref="propertyList"
    />
    <modal
      v-if="showModal"
      :show="showModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <template slot="header">
        <div
          class="d-flex justify-content-center font-weight-700 text-uppercase w-100"
        >
          {{ $t("PROPERTIES.PRINT_MODAL_HEADER") }}
        </div>
      </template>
      <form class="add-form pb-2" @submit.prevent="">
        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.AUCTION')}(*)`"
            :placeholder="$t('COMMON.AUCTION')"
          >
            <auction-selector
              :placeholder="$t('COMMON.AUCTION')"
              :auction="details.auction"
              :filterable="true"
              :showAll="false"
              :filterOrganization="items[0]?.organization?.id"
              @auctionChanged="
                (auctionId) => {
                  details.auction = auctionId;
                }
              "
            />
          </base-input>
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.CITY')} (*)`"
            :placeholder="$t('COMMON.CITY')"
            v-model="details.city"
          >
          </base-input>
        </div>

        <div class="form-wrapper">
          <base-input
            :label="`${$t('COMMON.DATE')} (*)`"
            :placeholder="$t('COMMON.DATE')"
            v-model="details.date"
          >
            <flat-picker
              :placeholder="$t('COMMON.DATE')"
              :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="details.date"
            >
            </flat-picker>
          </base-input>
        </div>
      </form>
      <template slot="footer">
        <button
          v-if="selectedProperties.length !== 0"
          type="submit"
          class="btn btn-primary"
          @click="printSelection"
          :disabled="details.city === '' || details.date === '' || details.auction === ''"
        >
          {{ $t("PROPERTIES.PRINT") }}
        </button>

        <button
          v-else
          type="submit"
          class="btn btn-primary"
          @click="print"
          :disabled="details.city === '' || details.date === '' || details.auction === ''"
        >
          {{ $t("PROPERTIES.PRINT") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import PropertyListTable from "../../PropertyManagement/partials/PropertyListTable.vue";
import { Button, Option, Select } from "element-ui";
import swal from "sweetalert2";
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AuctionSelector from "../../../../../components/AuctionSelector.vue";

export default {
  name: "auction-view-properties",

  components: {
    flatPicker,
    AuctionSelector,
    PropertyListTable,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: ["auction"],

  mixins: [],

  data() {
    return {
      onlyConfirmed: false,
      selectedProperties: [],
      showModal: false,
      details: {
        city: "",
        date: "",
        auction: "",
        timezone: "",
      },
      items: "",
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  },

  computed: {},

  methods: {
    async triggerDataExport(value) {
      this.$root.$emit("handleDataExport", {
        onlyConfirmed: value,
      });
    },

    async getSelectedProperties(properties) {
      this.selectedProperties = properties;
    },

    async exportPublicNotices() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const excelResponse = await this.$store.dispatch(
          "properties/exportPublicNotices"
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.PUBLIC_NOTICE_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        swal.close();
      } catch (error) {
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const pdfResponse = await this.$store.dispatch(
          "properties/printPdfLetters",
          this.details
        );
        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.LETTERS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        const excelResponse = await this.$store.dispatch(
          "properties/exportThumbnails"
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.THUMBNAILS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        swal.close();
        this.showModal = false;
        this.details.auction = "";
      } catch (error) {
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else if (error?.response?.status === 400) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.ALL_FIELDS_REQUIRED"),
          });
        } else if (error?.response?.status === 403) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.TEMPLATES_REQUIRED"),
          });
        }
         else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
        // this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async printSelection() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const data = {
          selectedProperties: this.selectedProperties,
          details: this.details,
        };
        const pdfResponse = await this.$store.dispatch(
          "properties/printLettersForSelection",
          data
        );
        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("PROPERTIES.LETTERS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        const excelResponse = await this.$store.dispatch(
          "properties/exportThumbnailsForSelection",
          this.selectedProperties
        );
        const excelPath = window.URL.createObjectURL(
          new Blob([excelResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        const downloadExcelLink = document.createElement("a");
        downloadExcelLink.href = excelPath;
        downloadExcelLink.download =
          this.$t("PROPERTIES.THUMBNAILS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".xlsx";

        document.body.appendChild(downloadExcelLink);
        downloadExcelLink.click();
        document.body.removeChild(downloadExcelLink);
        this.details.auction = "";

        swal.close();
        this.showModal = false;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else if (error?.response?.status === 400) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.ALL_FIELDS_REQUIRED"),
          });
        } else if (error?.response?.status === 403) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.TEMPLATES_REQUIRED"),
          });
        }
         else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
        swal.close();
      }
    },

    openDetailsModal() {
      this.items = this.$store.getters["properties/list"];
      this.items.length &&
        (this.details.city = this.items[0].organization.billing_city);
      console.log(this.$fillUserOrganizationData());
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      this.details.date = `${year}-${month}-${day}`;
      this.details.timezone = this.userTimezone;
      this.showModal = true;
    },
  },

  mounted() {},

  watch: {
    auction(auction) {},
  },
};
</script>
